import React, { useState } from 'react';

const ImageGallery = () => {
  const images = [
    '/camera/zabra gpm_1.jpeg',
    '/camera/zabra gpm_2.jpeg',
    '/camera/zabra gpm_3.jpeg',
    '/camera/zabra gpm_4.jpeg',
    '/camera/zabra gpm_5.jpeg',
    '/camera/zabra gpm_6.jpeg',
    '/camera/zabra gpm_7.jpeg',
    '/camera/zabra gpm_8.jpeg',
    '/camera/zabra gpm_9.jpeg',
    '/camera/zabra gpm_10.jpeg',
    '/camera/zabra gpm_11.jpeg',
    '/camera/zabra gpm_12.jpeg',
    '/camera/zabra gpm_13.jpeg',
    '/camera/zabra gpm_14.jpeg',
    '/camera/zabra gpm_15.jpeg',
    '/camera/zabra gpm_16.jpeg',
    '/camera/bengkel zabra 1.jpeg',
    '/camera/bengkel zabra 2.jpeg',
    '/camera/mengajar zabra 1.jpeg',
    '/camera/mengajar zabra 2.jpeg',
    '/camera/mengajar zabra 5.jpeg',
    '/camera/mengajar zabra 6.jpeg',
    '/camera/murid training zabra 1.jpeg',
    '/camera/murid training zabra 2.jpeg',
    '/camera/murid training zabra 3.jpeg',
    '/camera/murid training zabra 4.jpeg',
    '/camera/repair zabra 1.jpeg',
    '/camera/repair zabra 2.jpeg',
    '/camera/repair zabra 3.jpeg',
    '/camera/repair zabra 4.jpeg',
    '/camera/rak zabra 2.jpeg',
  ];

  const initialImageCount = 10;
  const [visibleImages, setVisibleImages] = useState(initialImageCount);
  const [hasExpanded, setHasExpanded] = useState(false);

  const loadMoreImages = () => {
    setVisibleImages(prevCount => Math.min(prevCount + 10, images.length));
    setHasExpanded(true);
  };

  const collapseGallery = () => {
    setVisibleImages(initialImageCount);
    setHasExpanded(false);
  };

  return (
    <div>
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-2">
        {images.slice(0, visibleImages).map((src, index) => (
          <div key={index} className="aspect-square overflow-hidden rounded-lg">
            <img
              src={src}
              alt={`Company work ${index + 1}`}
              className="w-full h-full object-cover transform hover:scale-110 transition duration-300"
            />
          </div>
        ))}
      </div>
      <div className="text-center mt-8 space-x-4">
        {visibleImages < images.length && (
          <button
            onClick={loadMoreImages}
            className="bg-[#F29200] text-white py-2 px-6 rounded-full font-semibold hover:bg-[#D97E00] transition duration-300"
          >
            Lihat Lagi
          </button>
        )}
        {hasExpanded && (
          <button
            onClick={collapseGallery}
            className="bg-gray-200 text-gray-800 py-2 px-6 rounded-full font-semibold hover:bg-gray-300 transition duration-300"
          >
            Simpan
          </button>
        )}
      </div>
    </div>
  );
};

export default ImageGallery;