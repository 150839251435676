// import React, { useState, useEffect } from 'react';
// import { Link, useLocation, useNavigate } from 'react-router-dom';
// import { Menu, X, ChevronDown } from 'lucide-react';

// const Header = () => {
//   const [isMenuOpen, setIsMenuOpen] = useState(false);
//   const [isScrolled, setIsScrolled] = useState(false);
//   const location = useLocation();
//   const navigate = useNavigate();

//   useEffect(() => {
//     const handleScroll = () => {
//       setIsScrolled(window.scrollY > 10);
//     };
//     window.addEventListener('scroll', handleScroll);
//     return () => window.removeEventListener('scroll', handleScroll);
//   }, []);

//   const handleSmoothScroll = (event, sectionId) => {
//     event.preventDefault();
//     setIsMenuOpen(false);

//     if (location.pathname !== '/') {
//       navigate('/');
//       setTimeout(() => {
//         scrollToSection(sectionId);
//       }, 100);
//     } else {
//       scrollToSection(sectionId);
//     }
//   };

//   const scrollToSection = (sectionId) => {
//     const section = document.getElementById(sectionId);
//     if (section) {
//       const yOffset = -80;
//       const y = section.getBoundingClientRect().top + window.pageYOffset + yOffset;
//       window.scrollTo({ top: y, behavior: 'smooth' });
//     }
//   };

//   const handleTopScroll = (event) => {
//     event.preventDefault();
//     setIsMenuOpen(false);

//     if (location.pathname !== '/') {
//       navigate('/');
//       setTimeout(() => {
//         window.scrollTo({ top: 0, behavior: 'smooth' });
//       }, 100);
//     } else {
//       window.scrollTo({ top: 0, behavior: 'smooth' });
//     }
//   };

//   return (
//     <header className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${isScrolled ? 'bg-white shadow-lg' : 'bg-transparent'}`}>
//       <div className="container mx-auto px-4">
//         <div className="flex justify-between items-center py-4">
//           <a href="/" onClick={handleTopScroll} className="flex items-center">
//             <img src="/logo-zabra.png" alt="Logo Syarikat" className="h-16 md:h-24 transition-all duration-300" />
//           </a>
//           <nav className="hidden md:flex space-x-6 items-center">
//             <NavLink href="/" onClick={handleTopScroll}>Laman Utama</NavLink>
//             <NavLink href="/#kami-tawarkan" onClick={(e) => handleSmoothScroll(e, 'kami-tawarkan')}>Kami Tawarkan</NavLink>
//             <NavLink href="/#services" onClick={(e) => handleSmoothScroll(e, 'services')}>Perkhidmatan</NavLink>
//             <Link to="/contact" className="bg-[#F29200] text-white hover:bg-[#D97E00] px-6 py-3 rounded-full text-lg font-semibold transition duration-300 transform hover:scale-105">
//               Hubungi Kami
//             </Link>
//           </nav>
//           <button
//             onClick={() => setIsMenuOpen(!isMenuOpen)}
//             className="md:hidden bg-[#F29200] text-white p-2 rounded-full transition duration-300 transform hover:scale-110 focus:outline-none"
//           >
//             {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
//           </button>
//         </div>
//       </div>
//       <MobileMenu isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} handleSmoothScroll={handleSmoothScroll} />
//     </header>
//   );
// };

// const NavLink = ({ href, onClick, children }) => (
//   <a href={href} onClick={onClick} className="text-gray-700 hover:text-[#F29200] text-lg font-medium transition duration-300">
//     {children}
//   </a>
// );

// const MobileMenu = ({ isOpen, onClose, handleSmoothScroll }) => {
//   return (
//     <div
//       className={`fixed inset-0 bg-black bg-opacity-50 z-50 transition-opacity duration-300 ${
//         isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
//       }`}
//       onClick={onClose}
//     >
//       <div
//         className={`absolute right-0 top-0 bottom-0 w-64 bg-white shadow-xl transform transition-transform duration-300 ease-in-out ${
//           isOpen ? 'translate-x-0' : 'translate-x-full'
//         }`}
//         onClick={(e) => e.stopPropagation()}
//       >
//         <div className="flex flex-col h-full">
//           <div className="flex justify-end p-4">
//             <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
//               <X size={24} />
//             </button>
//           </div>
//           <nav className="flex-grow">
//             <MobileNavLink href="/" onClick={(e) => { onClose(); handleSmoothScroll(e, null); }}>Laman Utama</MobileNavLink>
//             <MobileNavLink href="/#kami-tawarkan" onClick={(e) => handleSmoothScroll(e, 'kami-tawarkan')}>Kami Tawarkan</MobileNavLink>
//             <MobileNavLink href="/#services" onClick={(e) => handleSmoothScroll(e, 'services')}>Perkhidmatan</MobileNavLink>
//             <Link
//               to="/contact"
//               className="block py-3 px-6 bg-[#F29200] text-white text-lg font-semibold hover:bg-[#D97E00] transition duration-300"
//               onClick={onClose}
//             >
//               Hubungi Kami
//             </Link>
//           </nav>
//         </div>
//       </div>
//     </div>
//   );
// };

// const MobileNavLink = ({ href, onClick, children }) => (
//   <a
//     href={href}
//     onClick={onClick}
//     className="block py-3 px-6 text-gray-700 hover:bg-gray-100 text-lg font-medium transition duration-300"
//   >
//     {children}
//   </a>
// );

// export default Header;

import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Menu, X } from 'lucide-react';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const handleSmoothScroll = (event, sectionId) => {
    event.preventDefault();
    setIsMenuOpen(false);

    if (location.pathname !== '/') {
      navigate('/');
      setTimeout(() => {
        scrollToSection(sectionId);
      }, 100);
    } else {
      scrollToSection(sectionId);
    }
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const yOffset = -80;
      const y = section.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };

  const handleTopScroll = (event) => {
    event.preventDefault();
    setIsMenuOpen(false);

    if (location.pathname !== '/') {
      navigate('/');
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, 100);
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  return (
    <header className="bg-white shadow-md sticky top-0 z-50">
    <div className="container mx-auto px-4">
      <div className="flex justify-between items-center py-1">
        <a href="/" onClick={handleTopScroll} className="flex items-center">
          <img src="/logo-zabra.png" alt="Logo Syarikat" className="h-16 md:h-24 transition-all duration-300" />
        </a>
        <nav className="hidden md:flex space-x-6 items-center">
          <NavLink href="/" onClick={handleTopScroll}>Laman Utama</NavLink>
          <NavLink href="/#kami-tawarkan" onClick={(e) => handleSmoothScroll(e, 'kami-tawarkan')}>Kami Tawarkan</NavLink>
          <NavLink href="/#services" onClick={(e) => handleSmoothScroll(e, 'services')}>Perkhidmatan</NavLink>
          <Link to="/contact" className="bg-[#25D366] text-white hover:bg-[#128C7E] px-6 py-3 rounded-full text-lg font-semibold transition duration-300 transform hover:scale-105">
            Hubungi Kami
          </Link>
        </nav>
        <button
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          className="md:hidden bg-[#F29200] text-white p-2 rounded-full transition duration-300 transform hover:scale-110 focus:outline-none"
        >
          {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
        </button>
      </div>
    </div>
    <MobileMenu isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} handleSmoothScroll={handleSmoothScroll} />
  </header>
  );
};

const NavLink = ({ href, onClick, children }) => (
  <a href={href} onClick={onClick} className="text-gray-700 hover:text-[#F29200] text-lg font-medium transition duration-300">
    {children}
  </a>
);

const MobileMenu = ({ isOpen, onClose, handleSmoothScroll }) => {
  return (
    <div
      className={`fixed inset-0 bg-black bg-opacity-50 z-50 transition-opacity duration-300 ${
        isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
      }`}
      onClick={onClose}
    >
      <div
        className={`absolute right-0 top-0 bottom-0 w-64 bg-white shadow-xl transform transition-transform duration-300 ease-in-out ${
          isOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex flex-col h-full">
          <div className="flex justify-end p-4">
            <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
              <X size={24} />
            </button>
          </div>
          <nav className="flex-grow">
            <MobileNavLink href="/" onClick={(e) => { onClose(); handleSmoothScroll(e, null); }}>Laman Utama</MobileNavLink>
            <MobileNavLink href="/#kami-tawarkan" onClick={(e) => handleSmoothScroll(e, 'kami-tawarkan')}>Kami Tawarkan</MobileNavLink>
            <MobileNavLink href="/#services" onClick={(e) => handleSmoothScroll(e, 'services')}>Perkhidmatan</MobileNavLink>
            <Link
              to="/contact"
              className="block py-3 px-6 bg-[#25D366] text-white text-lg font-semibold hover:bg-[#128C7E] transition duration-300"
              onClick={onClose}
            >
              Hubungi Kami
            </Link>
          </nav>
        </div>
      </div>
    </div>
  );
};

const MobileNavLink = ({ href, onClick, children }) => (
  <a
    href={href}
    onClick={onClick}
    className="block py-3 px-6 text-gray-700 hover:bg-gray-100 text-lg font-medium transition duration-300"
  >
    {children}
  </a>
);

export default Header;