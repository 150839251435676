// components/Footer.js
import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-black text-white py-4">
      <div className="container mx-auto px-4">

        {/* <div className="flex flex-wrap justify-between">
          <div className="w-full md:w-1/3 mb-6 md:mb-0">
            <h3 className="text-xl font-semibold mb-4">Zabra Solutions</h3>
            <p>Providing innovative solutions since 2013</p>
          </div>
          <div className="w-full md:w-1/3 mb-6 md:mb-0">
            <h3 className="text-xl font-semibold mb-4">Quick Links</h3>
            <ul>
              <li><Link to="/privacy" className="hover:text-[#F29200]">Privacy Policy</Link></li>
              <li><Link to="/terms" className="hover:text-[#F29200]">Terms of Service</Link></li>
            </ul>
          </div>
          <div className="w-full md:w-1/3">
            <h3 className="text-xl font-semibold mb-4">Follow Us</h3>
            <div className="flex space-x-4">
              <a href="#" className="hover:text-[#F29200]">FB</a>
              <a href="#" className="hover:text-[#F29200]">TW</a>
              <a href="#" className="hover:text-[#F29200]">IN</a>
            </div>
          </div>
        </div> */}

        <div className="text-center">
          <p>&copy; 2024 ZABRA SOLUTIONS. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;