// pages/AboutPage.js
import React from 'react';

const AboutPage = () => {
  return (
    <div className="py-20">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold mb-8 text-center">About Us</h2>
        <div className="flex flex-wrap -mx-4">
          <div className="w-full md:w-1/2 px-4 mb-8">
            <h3 className="text-xl font-semibold mb-4">Our Mission</h3>
            <p className="text-gray-600">To empower libraries and educational institutions with cutting-edge technology solutions.</p>
          </div>
          <div className="w-full md:w-1/2 px-4 mb-8">
            <h3 className="text-xl font-semibold mb-4">Our Vision</h3>
            <p className="text-gray-600">To be the leading provider of innovative library management systems and educational technology.</p>
          </div>
        </div>
        <div className="mt-8">
          <h3 className="text-xl font-semibold mb-4">Our History</h3>
          <p className="text-gray-600">Founded in 2013, Your Company has been at the forefront of educational technology...</p>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
